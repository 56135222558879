import Vue from "vue"
import VueRouter from "vue-router"
import DashBoard from "../views/DashBoard.vue"
import Document from "../views/Document.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "index",
    component: DashBoard,
  },
  {
    path: "/doc",
    name: "document",
    component: Document,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
})

export default router
