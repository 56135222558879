import axios from "axios"

const isDev = process.env.NODE_ENV === "development"
const baseUrl = isDev ? "http://test.ccsc58.com" : "https://api.ccsc58.com"
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
})
instance.interceptors.request.use(
  (config) => {
    config.params = { ...config.data, login_type: 1 }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { baseUrl, instance }
