<template>
  <div>
    <div class="main">
      <div class="title">
        <div class="left">
          <span class="logo">CIMC</span>
          <a href="/" class="a">
            <span style="font-wight: 600">中集智本</span>
          </a>
          <el-dropdown trigger="hover">
            <span>・{{ currentName }}</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in moduleList"
                :key="index"
                @click.native="handleClick(item)"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <el-container v-loading="loading">
      <el-aside width="200px">
        <!-- class="el-menu-vertical-demo" -->
        <el-menu :default-openeds="defaultOpeneds" :default-active="defaultActive">
          <div v-for="(item, index) in menus" :key="index">
            <template v-if="item.children.length > 0">
              <el-submenu :index="item.id + ''">
                <template slot="title">
                  <!-- <i class="el-icon-location" /> -->
                  <span>{{ item.name }}</span>
                </template>
                <el-menu-item
                  v-for="i in item.children"
                  :key="i.id + ''"
                  :index="i.id + ''"
                  @click.native="handleSelect(i)"
                >
                  <span slot="title">{{ i.name }}</span>
                </el-menu-item>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item
                :key="item.id + ''"
                :index="item.id + ''"
                @click.native="handleSelect(item)"
              >
                <span slot="title">{{ item.name }}</span>
              </el-menu-item>
            </template>
          </div>
        </el-menu>
      </el-aside>
      <el-main>
        <div class="main-box">
          <el-breadcrumb separator="/" style="padding-left: 50px">
            <el-breadcrumb-item :to="{ path: '/' }">中集智本官方文档</el-breadcrumb-item>
            <el-breadcrumb-item>
              {{ currentName }}
            </el-breadcrumb-item>
            <el-breadcrumb-item>
              {{ breadcrumbName }}
            </el-breadcrumb-item>
            <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
          </el-breadcrumb>
          <EditorBoard ref="editor" />
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
  import EditorBoard from "./Editor.vue"
  import NProgress from "nprogress"
  import "nprogress/nprogress.css"
  import { getDocumentsTree, getDocumentsModules } from "@/api"
  export default {
    name: "DocuMent",
    components: { EditorBoard },
    beforeRouteLeave(to, from, next) {
      //离开组件的时候触发
      //什么都不写的时候，不会离开(走下一步)
      console.log(to)

      next()
    },
    data() {
      return {
        menus: [],
        defaultOpeneds: [],
        loading: false,
        content: "",
        breadcrumbName: "",
        EditorBoardKey: 0,
        EditorShow: false,
        moduleList: [],
        currentName: "",
        defaultActive: "",
      }
    },
    beforeDestroy() {
      sessionStorage.removeItem("activeId")
      sessionStorage.removeItem("mk_id")
    },
    mounted() {
      const { id, name } = this.$route.query
      console.log(sessionStorage.getItem("mk_id"))
      if (!sessionStorage.getItem("mk_id")) {
        this.getMenus(id)
        this.getAllMoudles(id)
      } else {
        this.getMenus(sessionStorage.getItem("mk_id"))
        this.getAllMoudles(sessionStorage.getItem("mk_id"))
      }
    },
    methods: {
      getMenus(id) {
        this.loading = true
        getDocumentsTree({ mokuaiId: id }).then((res) => {
          if (res.code == 200) {
            this.loading = false
            this.menus = res.data
            let target = []
            this.getActiveItem(sessionStorage.getItem("activeId"), res.data, target)
            let target1 = res.data[0].children.length > 0 ? res.data[0].children[0] : res.data[0]
            this.handleSelect(target[0] || target1)
            // this.breadcrumbName = res.data.data[0].name
            // if (res.data[0].children.length) {
            //   if (sessionStorage.getItem("activeId")) {
            //     this.getActiveItem(sessionStorage.getItem("activeId"), res.data)
            //     return
            //     let id = sessionStorage.getItem("activeId") + ""
            //     this.defaultActive = sessionStorage.getItem("activeId") + ""

            //     this.handleSelect(res.data[0].children[0])
            //   } else {
            //     this.defaultActive = res.data[0].children[0].id + ""
            //     this.handleSelect(res.data[0].children[0])
            //   }
            // } else {
            //   this.handleSelect(res.data[0])
            //   this.defaultActive = res.data[0].id + ""
            // }
            let defaultOpeneds = []
            res.data.forEach((item) => {
              if (item.children.length) {
                defaultOpeneds.push(item.id + "")
              }
            })
            this.defaultOpeneds = defaultOpeneds
          }
        })
      },
      getActiveItem(id, tree, target) {
        if (id == "undefined") {
          res = tree[0].children.length ? tree[0].children[0] : tree[0]
          target.push(res)
          return
        }
        tree.find((item) => {
          if (item.id == id) {
            target.push(item)
          } else {
            if (item.children.length) {
              this.getActiveItem(id, item.children, target)
            }
          }
        })
      },
      getAllMoudles(id) {
        getDocumentsModules().then((res) => {
          //
          if (res.code == 200) {
            this.moduleList = res.data
            this.currentName = res.data.find((item) => item.id == id).name
          }
        })
      },
      handleSelect(item) {
        console.log(item)
        NProgress.start()
        this.EditorShow = false
        this.breadcrumbName = item.name
        this.defaultActive = item.id + ""
        sessionStorage.setItem("activeId", item.id)
        // this.EditorBoardKey += 1
        // this.EditorShow = true
        this.$refs["editor"].layout(item.content)
        NProgress.done()
      },
      handleClick(item) {
        if (item.name == this.currentName) return
        sessionStorage.setItem("mk_id", item.id)
        this.currentName = item.name
        this.getMenus(item.id)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .main {
    background-color: #f6f6f6;
    height: 60px;
    line-height: 60px;
    position: fixed;
    width: 100%;
    z-index: 99;
    .title {
      font-size: 24px;
      .left {
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 28px;
        position: relative;
        .logo {
          color: #2373d0;
          margin-right: 5px;
        }
        :nth-child(3) {
          font-weight: 500;
          font-size: 24px;
          cursor: pointer;
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
    }

    .pro-item {
      .box-item {
        width: 100%;
        border-radius: 15px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  .left:after {
    position: absolute;
    right: -20px;
    top: 25px;
    width: 0px;
    height: 0px;
    content: " ";
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  .el-container {
    padding-top: 60px;
  }
  .el-menu {
    height: 100%;
    position: fixed;
    width: 200px;
    background-color: #f9f9f9 !important;
  }
  ::v-deep .el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
  }
  ::v-deep .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
  }
  .el-menu-item.is-active {
    font-weight: 500;
    background-color: #ecf5ff;
  }
  .el-aside {
    height: 100%;
    background-color: #f9f9f9 !important;
  }
  .main-box {
    width: 100%;
    height: 100%;
    // padding-left: 50px;
    box-sizing: border-box;
  }
  .a {
    text-decoration: none;
    color: #000;
  }
</style>
<style>
  /* .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .el-menu--collapse .el-submenu__title span {
    display: none;
  } */
  /*隐藏 > */
  /* .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
    display: none;
  } */
</style>
