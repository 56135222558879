<template>
  <div>
    <el-row>
      <el-col :span="18" style="padding: 0 50px">
        <!-- <div v-html="html"></div> -->
        <Editor v-model="html" :default-config="editorConfig" :mode="mode" @onCreated="onCreated" />
      </el-col>
      <el-col :span="6">
        <div class="my-menus">
          <el-tabs v-model="activeName" tab-position="right">
            <el-tab-pane v-for="(item, index) in menuList" :key="item.id" :name="'tab' + index">
              <span
                slot="label"
                :style="`margin-left:${item.level * 10}px;`"
                @click="scrollToEle(item, index)"
              >
                {{ item.title }}
              </span>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>

    <button v-show="backUpshow" class="top" @click="toTop">
      <i class="el-icon-upload2" />
    </button>
  </div>
</template>
<script>
  import Vue from "vue"
  import { Editor, Toolbar } from "@wangeditor/editor-for-vue"

  export default Vue.extend({
    components: { Editor },
    data() {
      return {
        editor: null,
        toolbarConfig: {
          insertKeys: {
            index: 35, // 插入的位置，基于当前的 toolbarKeys
            keys: ["createMenu"],
          },
        },
        editorConfig: {
          placeholder: "请输入内容...",
          readOnly: true,
        },
        mode: "default", // or 'simple'
        menuList: [],
        currentMenu: [],
        activeName: "tab0",
        scroll: "",
        backUpshow: false,
        html: "暂无文档信息",
        // html: "<h1>标题1</h1> <p>正文见佛都是佛啊还是豆腐哈送话费哦互粉的是撒谎都iUSA骚得很骚很嗲i皇帝</p><h3>标题2</h3><h1>wangEditorssss</h1><h3>标题</h3><h3>标题2</h3><h3>标题2</h3><h3>标题24</h3><h3>标题2</h3><h3>标题22</h3><h3>标题23</h3><h3>标题2</h3><h3>标题2</h3><h3>标题2</h3><h3>标题2</h3><h3>标题2</h3> <div>jdfosahdkh dhaosdosahodhsahdos</div><p>正文见佛都是佛啊还是豆腐哈送话费哦互粉的是撒谎都iUSA骚得很骚很嗲i皇帝</p><h3>标题2</h3><h3>标题2</h3><h3>标题2</h3><h3>标题28</h3><h3>标题2</h3><p>wangEditor can only understand the HTML format from editor.getHtml() , but not all HTML formats.</p><p><br></p>",
      }
    },
    watch: {
      scroll: function (val) {
        this.backUpshow = val > 100
        this.loadScroll()
      },
    },


    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
      layout(content) {
        this.html = content || "暂无文档信息"
        this.$nextTick(() => {
          this.handlerMenu(content)
        })
        // this.handlerMenu(content)
      },
      toTop() {
        document.documentElement.scrollTop = 0
        this.activeName = "tab0"
      },
      //解析文本 生成导航菜单
      handlerMenu(html) {
        if (!html) {
          this.menuList = []
          return
        }
        let content = document.querySelector(".w-e-scroll")
        // return
        let els = content.querySelectorAll("h1,h2,h3,h4,h5,h6")
        let menus = []
        if (els) {
          els.forEach((item, index) => {
            let menu = {}
            menu.isActive = null
            // 增加一个id标识
            menu.title = item.innerText
            menu.scrollTop = item.offsetTop
            let level = item.tagName.toLowerCase().replace("h", "")
            menu.level = parseInt(level)
            item.setAttribute("id", `menus_${index + 1}`)
            menu.id = `#menus_${index + 1}`
            menus.push(menu)
          })
        }
        this.menuList = menus

        this.listenScroll()
      },
      // 监听目录导航，设置选中时的样式
      listenScroll() {
        //防抖
        let fn = this.debounce(() => {
          this.scroll = document.documentElement.scrollTop || document.body.scrollTop

          if (this.menuList) {
            this.menuList.forEach((item, index, list) => {
              let scrollTop = (document.documentElement.scrollTop || document.body.scrollTops) + 2
              if (
                index == 0 &&
                scrollTop >= item.scrollTop &&
                scrollTop < list[index + 1].scrollTop
              ) {
                item.isActive = true
              } else if (
                index > 0 &&
                index < list.length - 1 &&
                scrollTop >= item.scrollTop &&
                scrollTop < list[index + 1].scrollTop
              ) {
                item.isActive = true
              } else if (index === list.length - 1 && scrollTop >= item.scrollTop) {
                item.isActive = true
              } else {
                item.isActive = false
              }
            })
          }
        }, 200)
        window.addEventListener("scroll", () => fn())
      },
      scrollToEle(item, index) {
        window.scrollTo(0, item.scrollTop - 60)
      },
      loadScroll() {
        let self = this
        let navs = document.querySelectorAll(".el-tabs__item")
        for (var i = self.menuList.length - 1; i >= 0; i--) {
          if (self.scroll >= self.menuList[i].scrollTop - 60) {
            self.activeName = "tab" + i
            break
          }
        }
      },
      // 函数防抖
      debounce(handle, delay) {
        let timer = null
        return function () {
          let _self = this,
            _args = arguments
          clearTimeout(timer)
          timer = setTimeout(function () {
            handle.apply(_self, _args)
          }, delay)
        }
      },
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
    },
  })
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
  .my-menus {
    position: fixed;
    z-index: 990;
  }
  .active {
    color: red;
  }
  ::v-deep .el-tabs__item {
    height: 30px !important;
    line-height: 30px !important;
  }
  .el-breadcrumb {
    height: 40px;
    position: fixed;
    line-height: 40px;
    top: 60px;
    z-index: 99;
    background: #fff;
    width: 100%;
  }
  .top {
    position: fixed;
    bottom: 50px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    padding: none;
    text-align: center;
    line-height: 40px;
    right: 100px;
    border: none;
    background: #409eff;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
</style>
