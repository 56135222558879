import Vue from "vue"
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
  ButtonGroup,
  Select,
  Row,
  Col,
  Container,
  Header,
  Aside,
  Main,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tabs,
  TabPane,
  Backtop,
  Breadcrumb,
  BreadcrumbItem,
  Loading,
} from "element-ui"
Vue.use(Container)
Vue.use(Header)
Vue.use(Select)
Vue.use(Aside)
Vue.use(Row)
Vue.use(Col)
Vue.use(Main)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(MenuItem)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Backtop)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Loading)
