<template>
  <div class="content">
    <div class="header" />
    <div class="main">
      <div class="title">
        <div class="left">
          <span class="logo">CIMC</span>
          <span style="font-wight: 600">中集智本</span>
          <span>・官方说明文档</span>
        </div>
        <el-button-group>
          <el-button
            :type="type == '1' ? 'primary' : ''"
            size="small"
            style="width: 80px"
            @click="() => (type = '1')"
          >
            平台
          </el-button>
          <el-button
            :type="type == '2' ? 'primary' : ''"
            style="width: 80px"
            size="small"
            @click="() => (type = '2')"
          >
            产品
          </el-button>
        </el-button-group>
      </div>
      <div class="pro-item">
        <el-row :gutter="40">
          <el-col
            v-for="(item, index) in list"
            v-show="item.type == type"
            :key="index"
            style="margin-bottom: 30px"
            :span="8"
          >
            <div class="box-item" @click="goDocPage(item)">
              <img :src="item.image" style="width: 100%" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  import { getDocumentsModules } from "@/api"
  export default {
    components: {},
    data() {
      return {
        type: "1",
        list: [],
      }
    },
    mounted() {
      getDocumentsModules().then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.list = res.data
          sessionStorage.removeItem("activeId")
          sessionStorage.removeItem("mk_id")
        }
      })
    },
    methods: {
      switchType(val) {},
      goDocPage(item) {
        this.$router.push({ path: "/doc", query: { id: item.id, name: item.name } })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .header {
    background-color: #e4e4e4;
    color: #333;
    height: 60px;
    width: 100%;
  }
  .el-button ::v-deep {
    border-radius: 0;
  }
  .content {
    background-color: #f6f6f6;
    min-width: 1000px;
    height: 100%;
  }
  .main {
    height: calc(100vh - 60px);
    overflow: auto;
    padding: 0 170px;
    // margin: 0 auto;
    min-width: 1000px;
    box-sizing: border-box;
    .title {
      font-size: 28px;

      box-sizing: border-box;
      .left {
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 30px;
        .logo {
          color: #2373d0;
          margin-right: 5px;
        }
        :nth-child(3) {
          font-weight: 500;
          font-size: 26px;
        }
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      .btn-group {
        display: flex;
      }
    }
    .pro-item {
      .box-item {
        width: 100%;
        border-radius: 15px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.5s;
        // box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
</style>
