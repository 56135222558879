import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=7998800d&scoped=true"
import script from "./Editor.vue?vue&type=script&lang=js"
export * from "./Editor.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Editor.vue?vue&type=style&index=1&id=7998800d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7998800d",
  null
  
)

export default component.exports