import Vue from "vue"
import router from "./router"
import { Boot } from "@wangeditor/editor"
import { myButtonMenu } from "./views/plugin.js"
Boot.registerMenu(myButtonMenu)
import "./plugin/element.js"
import App from "./App.vue"
Vue.config.productionTip = false
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app")
